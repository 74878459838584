export interface HeaderUtilisationPercentageModel {
  status: boolean;
  statusCode: number;
  message: string;
  result: number;
}

export const EMPTY_UTILISATION_MODEL_RESPONSE: HeaderUtilisationPercentageModel = {
  message: '',
  status: true,
  statusCode: 0,
  result: 0,
};
